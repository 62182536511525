/* AuthLayout.css */
.hero-bg::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('../../assets/images/ConstructionsOps-Photoshoot.png')
    no-repeat;
  background-size: cover;
  background-position: top left;
  z-index: -10;
}

@media (max-width: 1024px) {
  .fixed-header-position {
    margin-top: 111.5px;
  }
  .positionginmobile {
    padding-top: 120.5px;
  }
}

@media (max-width: 639px) {
  .fixed-header-position {
    margin-top: 127.5px;
  }
}

@media (max-width: 394px) {
  .fixed-header-position {
    margin-top: 108.5px;
  }
}
