.focus-trap {
  position: relative;
  z-index: 100;
}



/* OVERRIDE VIEW CROP IMAGES */
.COS-React-Crop .ReactCrop__crop-selection:not(.ReactCrop--no-animate .ReactCrop__crop-selection) {
  -webkit-animation-iteration-count: unset;
  -webkit-animation-timing-function: unset;
  -webkit-animation-play-state: unset;
  animation-iteration-count: unset;
  animation-play-state: unset;
  animation: unset;
  animation-timing-function: unset;

  /* background-image: linear-gradient(to right, #62C8FB 50%, #444 50%),
    linear-gradient(to right, #62C8FB 50%, #444 50%),
    linear-gradient(to bottom, #62C8FB 50%, #444 50%),
    linear-gradient(to bottom, #62C8FB 50%, #444 50%); */

  border: 1px dashed #62C8FB;
  background-image: unset;
}

.COS-React-Crop .ReactCrop__drag-handle {
  border-radius: 99px;
  border: 1px solid #62C8FB;
  height: 10px;
  width: 10px;
  background: #ffffff;

}