.hero-caption::after,
.hero-info::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0px;
  z-index: -1;
}
.sizing {
  width: 170px;
  height: auto;
}
.hero-caption::after {
  background: #ffb92c;
  /* clip-path: polygon(0 0, 100% 0, 88% 100%, 0% 100%); */
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
}

.hero-info::after {
  background: #07b9ec;
  /* clip-path: polygon(0 0, 88% 0, 76% 100%, 0% 100%) */
  clip-path: polygon(0 0, 90% 0, 80% 100%, 0% 100%);
}

@media (max-width: 1024px) {
  .hero-caption::after,
  .hero-info::after {
    clip-path: none;
  }
}

@media (min-width: 1024px) and (max-width: 1256px) {
  li,
  div.headtext {
    word-wrap: break-word;
    width: 340px;
  }
}

@media (min-width: 1025px) and (max-width: 1500px) {
  li,
  div.tracking-text {
    word-wrap: break-word;
    width: 340px;
  }
}
