.positionsetting {
  min-height: 485px;
  margin-top: 69px;
}

.positionsetting span a {
  text-decoration: none;
  color: #001f38;
}
.positionsetting span a:hover {
  text-decoration: underline;
}

.logo_space {
  height: 97px;
}
.error_space {
  height: 22px;
}

span[role='link'] {
  color: #07b9ec;
  font-size: 16px;
  cursor: pointer;
}
