::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: #a8b1b8;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* In White Backgrounds: */
.cos-secondary-scrollbar ::-webkit-scrollbar-track {
  background: #edeeef !important;
}

/* In Dark Backgrounds: */
.cos-dark-scrollbar ::-webkit-scrollbar-track {
  background: #ffffff !important;
}

/* In Grey Backgrounds:  <--- default */
.cos-primary-scrollbar ::-webkit-scrollbar-track {
  background: #ffffff !important;
  box-shadow: 0 0 3px #a8b1b880;
}
