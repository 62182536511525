.setting_icon__hover_withing:hover .setting_icon,
.Mui-focusVisible.setting_icon__hover_withing .setting_icon,
.setting_icon__active .setting_icon,
.setting_icon:hover {
  content: url(./assets/svgs/staticColorSvg//hoverSettingImg.svg);
}

.language_icon__hover_withing:hover .language_icon,
.Mui-focusVisible.language_icon__hover_withing .language_icon,
.language_icon__active .language_icon,
.language_icon:hover {
  content: url(./assets/svgs/staticColorSvg//hoverLanguageImg.svg);
}

.logout_icon__hover_withing:hover .logout_icon,
.Mui-focusVisible.logout_icon__hover_withing .logout_icon,
.logout_icon__active .logout_icon,
.logout_icon:hover {
  content: url(./assets/svgs/staticColorSvg/hoverLogoutImg.svg);
}

.delete_icon__hover_withing:hover .delete_icon,
.Mui-focusVisible.delete_icon__hover_withing .delete_icon,
.delete_icon__active .delete_icon,
.delete_icon:hover {
  content: url(./assets/svgs/staticColorSvg/Delete-icon-white.svg);
}